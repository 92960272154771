import styled from "styled-components"
import CountUp from "react-countup";
import { useState } from "react";

const GuessBox = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: column;
`;

const GuessLine = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(50px, 80px));
`;

const Words = styled.div`
  display:flex; 
  padding: 0.25rem;
  position:relative;
  background-color: var(--secondary-background);
  border-radius: 3px;
  grid-column: 1 / span 4;
  margin-right: 2px;
  align-items: center;
  justify-content: center;
  font-size: x-large;
`;

const Score = styled(Words)`
  grid-column: 5 / span 2;
  gap: 10px;
`;

export function Guesses({ guesses }) {
  const [animationHasEnded, setAnimationHasEnded] = useState(false);

  return (
    <GuessBox>
      {guesses.map((guess, index) => (
        <GuessLine key={index}>
          {guess.bracketWord.position === 'after' ?
          <Words>{guess.word} ({guess.bracketWord.word})</Words>
          :
          <Words>({guess.bracketWord.word}) {guess.word}</Words>
          }
          <Score>
            <CountUp
              end={guess.score}
              duration={2}
              decimals={1}
              suffix={"%"}
              onStart={() => setAnimationHasEnded(false)}
              onEnd={() => setAnimationHasEnded(true)}
            />
            {(animationHasEnded && Number(guess.score) >= Number(guess.bracketWord.bestScore.toFixed(1))) &&
              <span>🎉</span> 
            }
          </Score>
        </GuessLine>
      ))}
    </GuessBox>
  )
}