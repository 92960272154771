import CopyToClipboard from "react-copy-to-clipboard";
import React, { useMemo } from "react";
import { DateTime } from "luxon";
import { ButtonStyle } from "../globalStyles";
import styled from "styled-components";
import { toast } from "react-toastify";

const FIRST_DAY_OF_HAPAX = DateTime.fromFormat('November 09 2022', 'LLLL dd yyyy');

const Button = styled.button`
    ${ButtonStyle}
`;

const findBestScore = (guesses) => {
    let bestScoreSoFar = 0;
    for (const guess of guesses) {
        if (Number(guess.score) > bestScoreSoFar) {
            bestScoreSoFar = guess.score;
        }
    }
    return bestScoreSoFar;
};

const getShareString = (win, guesses) => {
  let string = "";
  if (win) {
    string += `I got it in ${guesses.length} ${guesses.length === 1 ? "guess" : "guesses"}! 🥳\n`;
    string += `The best score today is ${guesses[0].bracketWord.bestScore.toFixed(1)}%\n`
  } else {
    string += `I gave up after ${guesses.length -1} tries 🤷\n`;
    string += `My best score was ${findBestScore(guesses.slice(0,-1))}%\n`
  }
  return string;
}

export function Share({ win, guesses, end, dayString}) {
  const shareText = useMemo(() => {
    const currentDate = DateTime.now();
    console.log(currentDate)
    console.log(FIRST_DAY_OF_HAPAX)
    const diffInDays = Math.floor(currentDate.diff(FIRST_DAY_OF_HAPAX, 'days').toObject().days);
    let shareString = `#Hapax #${diffInDays}\n`;
    shareString += getShareString(win, guesses);
    shareString += "https://www.hapax.app";
    return shareString
  }, [guesses, dayString, win]);

  return (
    <CopyToClipboard
      text={shareText}
      onCopy={() => toast("Copied Results to Clipboard", { autoClose: 2000 })}
      options={{ format: "text/plain" }}
    >
      <Button disabled={!end}><span>Share Score</span></Button>
    </CopyToClipboard>
  )
}