import seedrandom from 'seedrandom';
import styled from "styled-components";
import { useState, useMemo, useEffect } from "react";
import { Guesses } from "./components/Guesses";
import { DateTime } from "luxon";
import { useGuesses } from "./hooks/useGuesses";
import bracketWords from './bracketWords.json';
import { calculatePercentage, Positions, lemmatize } from "./calculate_percentage";
import { ButtonStyle } from './globalStyles';
import { Share } from './components/Share';
import { ToastContainer, Flip, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Help } from './components/Help';

const Container = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Title = styled.div`
  display: flex;
  font-size: 4.5rem;
  cursor: pointer;
  font-weight: 500;
  user-select: none;
  -webkit-user-select: none; /*Safari*/
  -moz-user-select: none; /*Firefox*/
  color: var(--primary-logo);
`;

const InputArea = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  width: 80%;
`;

const Input = styled.input`
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid white;
  font-size: 2rem;
  flex: 1;
  width: 100%;
  max-width: 26rem;
  color: var(--primary-text);
  caret-color: var(--primary-text);
  font-family: inherit;
`;

const BracketWord = styled.div`
  font-size: 2rem;
`;

const BestScore = styled.div`
  font-size: 1.5rem;
`;

const Buttons = styled.div`
  display: flex;
  gap: 20px;
`;

const Button = styled.button`
  ${ButtonStyle}
`;

const GiveUpButton = styled(Button)`
  background-color: var(--secondary-button-unpressed);
`;

const isDev = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const getDayString = () => {
  return DateTime.now().toFormat(isDev() ? "yyyy-MM-dd-hh-mm-ss" : "yyyy-MM-dd");
}

function App() {
  const dayString = useMemo(getDayString, []);
  const [bracketWord, setBracketWord] = useState(bracketWords[Math.floor(seedrandom.alea(dayString)() * bracketWords.length)]);
  const [input, setInput] = useState("");
  const [guesses, addGuess] = useGuesses(dayString);
  const [end, setEnd] = useState(false);
  const [win, setWin] = useState(false);

  useEffect(() => {
    if (guesses.length > 0 && Number(guesses[guesses.length-1].score) >= Number(bracketWord.bestScore.toFixed(1))) {
      setEnd(true);
    }
  }
  , [guesses]);

  const handleInput = (e) => {
    setInput(e.target.value.trim());
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13 && !end) {
      handleGuess();
    }
  };

  const handleGuess = (e) => {
    if (input === "" || !/^[A-Za-z]+$/.test(input)) {
      toast("Invalid input. Only letters allowed!", {autoClose: 2000})
      return;
    }
    calculatePercentage(input, bracketWord.position === 'after' ? Positions.After : Positions.Before, bracketWord.word.split(" ").length)
    .then(words => {
      let score = words[lemmatize(bracketWord.word)] ?? 0;
      addGuess({word: input, bracketWord: bracketWord, score: score.toFixed(1)});
      setInput("");
      if (Number(score.toFixed(1)) >= Number(bracketWord.bestScore.toFixed(1))) {
        setEnd(true);
        setWin(true);
      }
    })
  }

  const handleGiveUp = (e) => {
    addGuess({word: bracketWord.answer, bracketWord: bracketWord, score: bracketWord.bestScore.toFixed(1)});
    setEnd(true);
  }

  return (
    <Container>
      <ToastContainer
        hideProgressBar
        position="top-center"
        transition={Flip}
        autoClose={false}
      />
      <Title>HAPAX</Title>
      <Help/>
      <BestScore>Best Score: {bracketWord.bestScore.toFixed(1)}%</BestScore>
      <InputArea>
      { bracketWord.position === 'after' ? 
      <>
        <Input onChange={handleInput}
          onKeyDown={handleEnter}
          value={input}
          disabled={end}
          type={"text"}
        />
        <BracketWord>({bracketWord.word})</BracketWord>
      </>
      :
      <>
        <BracketWord>({bracketWord.word})</BracketWord>
        <Input onChange={handleInput}
          onKeyDown={handleEnter}
          value={input}
          disabled={end}
          type={"text"}
        />
      </>
      }
      </InputArea>
      <Buttons>
        <Button onClick={handleGuess} disabled={end}>Guess!</Button>
        <GiveUpButton onClick={handleGiveUp} disabled={end}>Give up</GiveUpButton>
      </Buttons>
      <Guesses guesses={guesses}/>
      {end && 
      <Share
        win={win}
        guesses={guesses}
        end={end}
        dayString={dayString}
      />
      }
    </Container>
  );
}

export default App;
