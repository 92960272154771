import nlp from 'compromise';
import axios from 'axios';
// import nltkStopwords from 'nltk-stopwords';

export const Positions = {
    Before: 'before',
    After: 'after'
}

const get_response_list = (phrase, position, num_words) => {
  const bracket_words = '?'.repeat(num_words);
  const encoded_query = position === Positions.After ? encodeURI(`${phrase}${bracket_words}`) : encodeURI(`${bracket_words}${phrase}`);
  const uri = 'https://api.phrasefinder.io/search?'
  const params = {
    corpus: 'eng-gb',
    query: encoded_query
  }
  const param_string = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
  return axios.get(uri + param_string);
}

const is_word = (word) => /^[a-zA-Z]+$/.test(word);

const get_clean_responses = (response_list, position) => {
  return response_list.map(response => {
    let answer_words = [];

    if (position === Positions.After) {
      answer_words = response.tks.slice(1);
    } else {
      answer_words = response.tks.slice(0,-1);
    }

    const answer_list = answer_words.map(word => word.tt);
    const answer = answer_list.join(' ');
    
    if (response.sc > 0.001 && answer_list.every((word, index) => is_word(word))) {
      return {word: answer, count: response.mc}
    }
  }).filter(response => response !== undefined);
}

const lemmatize_and_sum = clean_responses => {
  const lemmatized = clean_responses.map(response => {return {word: nlp(response.word).compute('root').json()[0].terms.map(t=>t.root ?? t.normal).join(' '), count: response.count}});
  let lemmatized_dict = {};
  for (const response of lemmatized) {
    lemmatized_dict[response.word] = lemmatized_dict[response.word] ? lemmatized_dict[response.word] + response.count : response.count;
  }
  return lemmatized_dict;
}

const get_scores = (words) => {
  const total_score = Object.keys(words).reduce((acc, word) => acc + words[word], 0);
  for (const word in words) {
    words[word] = words[word]*100/total_score;
  }
  return words
}

export const lemmatize = word => nlp(word).compute('root').json()[0].terms.map(t=>t.root ?? t.normal).join(' ')

export const calculatePercentage = async (phrase, position, num_words) => {
  const response = await get_response_list(phrase, position, num_words);
  const clean_responses = get_clean_responses(response.data.phrases, position, num_words);
  const lemmatized_responses = lemmatize_and_sum(clean_responses);
  const word_scores = get_scores(lemmatized_responses);
  console.log(get_scores(lemmatized_responses))
  return word_scores;
}